@layer components {
  .carousel {
    --carousel-items-gap: initial;
    height: 100%;
    min-height: 100%;
    position: relative;
  }

  .track {
    display: flex;
    flex-direction: row;
    max-height: 100%;
    min-height: 100%;
    overflow-x: auto;
    overflow-x: overlay;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    gap: var(--carousel-items-gap);
  }

  .track > div,
  .track > span,
  .track > button,
  .track > a,
  .track > img {
    scroll-snap-align: var(--carousel-snap-alignment, start);
    position: relative;
  }

  .controls {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    padding: 0 var(--space-4x);
    transition: opacity 200ms;
    transition-delay: 100ms;
    width: 100%;
  }

  .control {
    align-items: center;
    display: flex;
    font-size: var(--font-size-xl);
    justify-content: center;
    padding: var(--space-2-5x);
    pointer-events: auto;
    border-radius: 100%;
    transition: opacity 200ms;
    transition-delay: 100ms;
    opacity: 1;
  }

  .disabled {
    visibility: hidden;
  }

  @media (--screen-sm) {
    .control {
      opacity: 0;
    }

    .carousel:hover .control:not(.disabled) {
      opacity: 1;
    }
  }

  .disableScrollBars::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0;
  }

  .disableScrollBars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
}
